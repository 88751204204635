import styles from "./LogAcessos.module.css";

import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";

import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import React, { useContext } from "react";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";

// COMPONENTES
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
import Menu from "../../Components/Menu/Menu";
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import axios from "axios";

const LogAcessos = () => {
    const { token, codemp } = useContext(AuthContext);
    const tableRef = useRef(null);

    const [logs, setLogs] = useState([]);
    const [semDados, setSemDados] = useState('');
    const [reload, setReload] = useState(false);

    useEffect(() => {
        setAuthToken(token);
      }, [token]);

      useEffect(() => {
        const source = axios.CancelToken.source();
        const CODEMP = codemp || sessionStorage.getItem("CODEMP");
        setLogs([])
        setSemDados('')
        const getLogs = async () => {
          try {
            const response = await api.get(`/sessao/listar/log?CODEMP=${CODEMP}`, {
              cancelToken: source.token
            });
            console.log(response.data);
            if(response.data.length === 0 ){
              setSemDados("Sem dados")
            } else {
              setLogs(response.data);
            }
          } catch (error) {
            if (axios.isCancel(error)) {
              console.log('Request canceled', error.message);
            } else {
              console.error(error);
            }
          } finally {
            setReload(false)
          }
        };
        getLogs();
        return () => {
          source.cancel('Component unmounted');
        };
      }, [codemp,reload]);  


      useEffect(() => {
        if (
          logs.length > 0 &&
          tableRef.current &&
          !$.fn.DataTable.isDataTable(tableRef.current)
        ) {

          $(tableRef.current).DataTable({
            language: {
              search: "Pesquisar",
              info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
            },
            lengthChange: false,
            //destroy: true,
            ordering: true,
            order: [0, 'desc']
          });
        } 
      }, [logs]);   

      
    function formatarData(dataString) {
      const data = new Date(dataString);
      
      const hh = String(data.getHours()).padStart(2, '0');
      const mm = String(data.getMinutes()).padStart(2, '0');
      const ss = String(data.getSeconds()).padStart(2, '0');
      const dd = String(data.getDate()).padStart(2, '0');
      const mmFormatado = String(data.getMonth() + 1).padStart(2, '0'); // Os meses em JavaScript são baseados em zero
      const aaaa = data.getFullYear();
    
      return `${dd}/${mmFormatado}/${aaaa} ${hh}:${mm}:${ss}`;
    }
      
  return (
    <React.Fragment>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
        <div className={styles.topo}>
          <div className={styles.topoButtons}>
            <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} onClick={() => window.location.reload()} title="Atualizar" ></i>
          </div>
          </div>
          <div className={styles.corpo}>
            <div className={`${styles.boxTable} container-fluid py-4`}>
              <div className="table-responsive p-0 pb-2">
                <h1 className={styles.titleTable}>Log de Acessos</h1>
                    <table
                      ref={tableRef}
                      id="tableLogs"
                      className={`${styles.table} table align-items-center justify-content-center mb-0`}
                    >
                      <thead>
                        <tr>
                          <th>Cód.Log</th>
                          <th>UUID</th>
                          <th>Data</th>
                          <th>Usuário</th>
                          <th>Dispositivo</th>
                          <th>Observação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {logs.length === 0 && semDados === '' ? <tr>
                      <td colSpan="8">
                        <LoadingCentroTelas />
                      </td>
                    </tr> : semDados === 'Sem dados' ? <tr>
                      <td style={{ textAlign: "center" }} colSpan="8">
                      A tabela está vazia no momento.
                      </td>
                    </tr> : (
                          logs.map((log) => {
                            if (!log.Usuario) {
                              return null;
                            }
                            return (
                              <React.Fragment key={log.CODLOGACE}>
                                  <tr key={log.CODLOGACE}>
                                    <td>{log.CODLOGACE}</td>
                                    <td title={log.CODIGO}>{log.CODIGO.length > 10 ? `${log.CODIGO.slice(0, 10)}...` : log.CODIGO}</td>
                                    <td>{log.DATAINC ? formatarData(log.DATAINC) : ""}</td>
                                    <td>
                                      {log.EMAIL
                                        ? log.EMAIL.toLowerCase()
                                        : ""}
                                    </td>
                                    <td>
                                      {log.DISPOSITIVO
                                        ? log.DISPOSITIVO.toUpperCase()
                                        : ""}
                                    </td>
                                    <td>{capitalizeFirstLetter(log.OBSERVACAO || "")}</td>
                                  </tr>
                              </React.Fragment>
                            );
                          })
                        )}
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
      </CaixaConteudo>
    </React.Fragment>
  )
}

export default LogAcessos